import React from 'react';
import { graphql } from 'gatsby';
import HeaderMain from '../components/HeaderMain';
import KprepSlider from '../components/pages/KPrepSlider.js';
import Footer from '../components/Footer';
import HeroSingle from '../components/pages/HeroSingle';
// images
import LineFull from '../images/linefull.jpg';
import SEO from '../components/seo';
import '../styles/app.scss';

const SchoolsOut = ({ data }) => {
    const { wordpressPage: post } = data;
    const test_title = post.yoast_title;
    const new_seo_title = test_title.replace('&#039;', "'");

    return (
        <>
            <SEO
                title={new_seo_title}
                meta={post.yoast_meta}
                description={
                    post.yoast_json_ld[0].wordpress__graph[1].description
                }
            />
            <HeaderMain />
            <HeroSingle pageTitle={post.title} />
            <section className="page-section smallestwdt respitesec">
                <div
                    className="container"
                    dangerouslySetInnerHTML={{
                        __html: post.content,
                    }}
                />
            </section>
            <section id='slider-krpep' className="page-section smallestwdt centersec">
                <div className="container">
				
				<div className="oplayhealwrap slids-kprep">
                        <div
                            id="myCarousel3"
                            className="carousel slide"
                            data-ride="carousel"
                        >
                            <KprepSlider
                                galleryImages={post.acf.kprep_gallery}
                            />
                        </div>
					{/* <h5 class="bluetxt">Classes/Events</h5> */}
					 {/* <iframe
                            src="https://werockthespectrumasheville.wrtsfranchise.com/classeshealc.php"
                            title="appointment"
                            className="healcode_widgets"
                        />  */}
                        {/* <healcode-widget data-type="class_lists" data-widget-partner="object" data-widget-id="02615912bfe" data-widget-version="0" /> */}
					</div>
					{/* <div className="oplayhealwrap pd-top">
					<h5 class="bluetxt">Summer Camp</h5>
					<a
                        href="https://clients.mindbodyonline.com/ASP/su1.asp?studioid=516911&tg=&vt=&lvl=&stype=&view=&trn=0&page=&catid=&prodid=&date=5%2f11%2f2021&classid=0&prodGroupId=&sSU=&optForwardingLink=&qParam=&justloggedin=&nLgIn=&pMode=0&loc=1"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="wrtsbtn yellowbtn max500"
                    >
                        Click Here to Sign Up for our Upcoming Special Events!
                    </a>			
	    

					</div> */}
					
                </div>
            </section>
            <Footer />
        </>
    );
};

export default SchoolsOut;

export const pageQuery = graphql`
    query($id: String!) {
        wordpressPage(id: { eq: $id }) {
            id
            title
            slug
            content
            yoast_meta {
                name
                property
            }
            yoast_title
            acf {
                kprep_gallery {
                    id
                    localFile {
                        id
                        childImageSharp {
                            fluid {
                                src
                            }
                        }
                    }
                }
                schools_out_call_number
                so_sessions_text
                so_enjoy_title
                so_enjoy_content
                so_sessions {
                    session_title
                    session_pricing
                }
                so_program_image {
                    localFile {
                        childImageSharp {
                            fluid {
                                srcWebp
                                src
                            }
                        }
                    }
                }
                so_enjoy_image {
                    localFile {
                        childImageSharp {
                            fluid {
                                srcWebp
                                src
                            }
                        }
                    }
                }
            }
            yoast_json_ld {
                wordpress__graph {
                    description
                }
            }
        }
    }
`;
